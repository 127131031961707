import React, { useCallback } from 'react';
import { PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { RescheduleDetailsViewModel } from '../../ViewModel/rescheduleDetailsViewModel/rescheduleDetailsViewModel';
import { classes, st } from './RescheduledBookingDetails.st.css';
import { DataHooks } from './constants';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export type RescheduledBookingDetailsProps = {
  viewModel: RescheduleDetailsViewModel;
};

const RescheduledBookingDetails: React.FC<RescheduledBookingDetailsProps> = ({
  viewModel,
}) => {
  const { navigateToMembersArea } = useCalendarActions();
  const onClick = useCallback(() => navigateToMembersArea(), [
    navigateToMembersArea,
  ]);
  const { notificationContent, buttonText } = viewModel;
  const { isMobile } = useEnvironment();

  return (
    <SectionNotification
      data-hook={DataHooks.RescheduledBookingDetails}
      type="default"
      className={st(classes.root, { isMobile })}
    >
      <SectionNotification.Text className={classes.content}>
        {notificationContent}
      </SectionNotification.Text>
      <SectionNotification.Button
        data-hook={DataHooks.Button}
        priority={PRIORITY.basic}
        size={SIZE.small}
        onClick={onClick}
        upgrade
      >
        {buttonText}
      </SectionNotification.Button>
    </SectionNotification>
  );
};

export default RescheduledBookingDetails;
