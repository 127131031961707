import React from 'react';
import {
  Toast as ToastComponent,
  TOAST_PLACEMENT,
  TOAST_SKIN,
} from 'wix-ui-tpa/Toast';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { ToastViewModel } from '../../ViewModel/toastViewModel/toastViewModel';
import { st, classes } from './Toast.st.css';
import { DataHooks } from './constants';

export type ToastProps = {
  viewModel: ToastViewModel;
};

const Toast: React.FC<ToastProps> = ({ viewModel }) => {
  const { onToastClose } = useCalendarActions();
  const { isMobile } = useEnvironment();
  const placement = isMobile
    ? TOAST_PLACEMENT.bottomFullWidth
    : TOAST_PLACEMENT.inline;

  return viewModel.show ? (
    <ToastComponent
      data-hook={DataHooks.Root}
      isShown
      skin={TOAST_SKIN.error}
      placement={placement}
      onClose={() => onToastClose()}
      className={st(classes.root, { isMobile })}
      shouldShowCloseButton
      shouldAnimate
    >
      {viewModel.text}
    </ToastComponent>
  ) : null;
};

export default Toast;
