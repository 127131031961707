import React from 'react';
import { TimezoneSelectionViewModel } from '../../../ViewModel/timezoneSelectionViewModel/timezoneSelectionViewModel';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import { classes, st } from './TimezoneSelection.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { TimezoneSelection as BaseTimezoneSelection } from '@wix/bookings-viewer-ui';

export type TimezoneSelectionProps = {
  viewModel: TimezoneSelectionViewModel;
};

const TimezoneSelection: React.FC<TimezoneSelectionProps> = ({ viewModel }) => {
  const { onTimezoneSelected } = useCalendarActions();
  const { isMobile } = useEnvironment();
  const {
    selectableTimezones,
    selectedTimezone,
    timezoneLocale,
    timezoneLabel,
  } = viewModel;

  return (
    <div className={st(classes.root, { isMobile })}>
      <BaseTimezoneSelection
        isMobile={isMobile}
        selectedTimezone={selectedTimezone}
        timezoneOptions={selectableTimezones}
        onTimezoneSelected={onTimezoneSelected}
        locale={timezoneLocale}
        timezoneLabel={timezoneLabel}
      />
    </div>
  );
};

export default TimezoneSelection;
