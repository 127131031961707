
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/work/e47ef567209736c/packages/bookings-calendar-widget/src/components/BookingCalendar/Widget/index.tsx';
  import {
    HttpProvider,
  } from '@wix/yoshi-flow-editor';

  import {
    withStyles
  } from '@wix/native-components-infra';

  

  import { initI18nWithoutICU as initI18n } from '@wix/yoshi-flow-editor-runtime/build/esm/i18next/init';



  import stylesParams from '/home/builduser/work/e47ef567209736c/packages/bookings-calendar-widget/src/components/BookingCalendar/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://5c05b49c51a145fb801c55a588742af8@sentry.wixpress.com/1120',
      id: '5c05b49c51a145fb801c55a588742af8',
      projectName: 'bookings-calendar-widget',
      teamName: 'bookings',
    };

  var UserComponent = getWidgetWrapper({
      initI18n,
      HttpProvider,
      withStylesHoc: withStyles,
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'BookingCalendar',
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };
